import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import {useLocalStorage} from 'usehooks-ts';
import _ from 'lodash';
import {toast} from 'react-toastify';
import {defaultSearchParams} from '^config/defaultSearchParams';
import {ApiContext} from '^contexts/api';
import {isResponseError} from '^utilities/isResponseError';
import {
    Accordion,
    Breadcrumb,
    Button,
    Col,
    Container,
    Form,
    Modal,
    OverlayTrigger,
    Popover,
    Row,
    Spinner,
} from 'react-bootstrap';
import {AsyncFormSelect, FormSelect} from '^common/formSelect';
import {PageActionFavorite} from '^common/pageAction';
import {PageHeader} from '^common/pageHeader';
import UnitGroup from './unitGroup';
import {InfoIcon} from '^pages/packing/common/icon';
import {
    getAssemblyParents,
    getKits,
    getStandaloneUnits,
} from '^pages/workTicket/unitOverview/unitOverviewHelper';

const EXCLUDE_STAGES = ['Rolled-Back'];

const WorkTicketUnitOverview = () => {
    const api = useContext(ApiContext);

    const [userFacility] = useLocalStorage('facility_id', '');
    const [userFacilityLabel] = useLocalStorage('facility_label', '');
    const {work_ticket_id: workTicketId} = useParams();
    const [workTicket, setWorkTicket] = useState(null);
    const [units, setUnits] = useState([]);
    const [totalUnits, setTotalUnits] = useState(null);
    const [groupByResource, setGroupByResource] = useState(true);
    const [showAssetDetails, setShowAssetDetails] = useState(true);
    const [stages, setStages] = useState([]);
    const [defaultResources, setDefaultResources] = useState([]);

    const [updateSelection, setUpdateSelection] = useState('');
    const [showOpenUpdateAll, setShowOpenUpdateAll] = useState(false);
    const [resources, setResources] = useState([]);
    const [unitResources, setUnitResources] = useState([]);
    const [unitStages, setUnitStages] = useState([]);
    const [disabled, setDisabled] = useState(false);
    const [updateSubmitted, setUpdateSubmitted] = useState(false);
    const [updateAllMessage, setUpdateAllMessage] = useState(null);

    const workTicketGroupId = workTicket
        ?.work_ticket_group?.work_ticket_group_id;

    const facilityId = workTicket
        ?.work_ticket_group?.facility?.facility_id;

    const checkForSerializedNullUnits = (units) => _.some(
        units, (unit) =>
            unit.serialized && _.isNil(unit.serial_number,
            ),
    );

    useEffect(() => {
        const fetchWorkTicket = async () => {
            setWorkTicket(null);
            setUnits([]);
            setTotalUnits(null);
            setUnitResources([]);
            setUnitStages([]);

            const response = await api.get(`/work-tickets/${workTicketId}`);

            if (isResponseError(response)) {
                toast.error(response.data.error);
                setTotalUnits(0);
                return;
            }

            const workTicket = response?.data;

            if (workTicket
                .work_ticket_group
                .facility
                .facility_id !== userFacility
            ) {
                toast.error('Work Ticket Not Found');
                setTotalUnits(0);
                return;
            }

            setWorkTicket(workTicket);
        };

        if (!api) {
            return;
        }

        if (!workTicketId) {
            return;
        }

        fetchWorkTicket();
    }, [api, userFacility, workTicketId]);

    useEffect(() => {
        const getUnits = async () => {
            let nextOffset = null;
            setUnits([]);

            do {
                const response = await api.get('/units', {
                    params: {
                        work_ticket_group_id: workTicketGroupId,
                        stage: _.join(stages, ','),
                        offset: nextOffset,
                    },
                });

                if (isResponseError(response)) {
                    toast.error(response?.data?.error);
                    nextOffset = null;
                    break;
                }

                setTotalUnits(response.data.total_count);

                setUnits((prevState) => _.uniqBy(
                    [...prevState, ...response.data.results],
                    'unit_id',
                ));

                nextOffset = response.data?.next_offset;
            } while (nextOffset);
        };

        if (!api) {
            return;
        }

        if (!workTicketGroupId) {
            return;
        }

        if (_.size(stages) < 1) {
            return;
        }

        getUnits();
    }, [api, stages, workTicketGroupId]);

    useEffect(() => {
        const fetchStages = async () => {
            const response = await api.get('/types/stage');

            if (isResponseError(response)) {
                return;
            }

            setStages(_.difference(response?.data, EXCLUDE_STAGES));
        };

        if (api) {
            fetchStages();
        }
    }, [api]);

    const searchResources = useCallback(async (label) => {
        if (!api) {
            return [];
        }

        const response = await api.get('/resources', {
            params: {
                active: 1,
                facility_id: facilityId,
                ...label ? {label} : {},
            },
        });

        if (isResponseError(response)) {
            return [];
        }

        const resources = response?.data?.results ?? [];

        setResources(resources);
        return _.map(resources, (resource) => ({
            label: resource?.label,
            value: resource?.resource_id,
        }));
    }, [api, facilityId]);

    useEffect(() => {
        const fetchDefaultResources = async () => {
            const resources = await searchResources();
            setDefaultResources(resources);
        };

        if (!api) {
            return;
        }

        if (!facilityId) {
            return;
        }

        // We only want to fetch the default resources for the select box
        //  once rather than for each row where the select is rendered
        fetchDefaultResources();
    }, [
        api,
        facilityId,
        searchResources,
    ]);

    useEffect(() => {
        if (showOpenUpdateAll) {
            setUpdateAllMessage(null);
        }
    }, [showOpenUpdateAll]);

    const updateUnit = useCallback((unit) => {
        setUnits((prevState) => _.map(
            prevState,
            (prev) => prev?.unit_id === unit?.unit_id
                ? unit
                : prev,
        ));
    }, []);

    const updateUnitValue = useCallback((unit, field, value) => {
        setUnits((prevState) => _.map(
            prevState,
            (prev) => {
                if (prev?.unit_id === unit?.unit_id) {
                    const cloned = _.cloneDeep(unit);
                    _.set(cloned, field, value);
                    if (cloned?.kit?.kit_id) {
                        if (field === 'resource') {
                            _.set(cloned, 'kit.resource', value);
                        } else {
                            _.set(cloned, 'kit.stage', value);
                        }
                    }
                    return cloned;
                }
                return prev;
            },
        ));
    }, []);

    const updateAllUnits = useCallback(async () => {
        if (!api) {
            return;
        }

        const applyUpdate = (unitsToUpdate) => _.forEach(
            unitsToUpdate,
            (unit) => updateUnitValue(
                unit,
                !groupByResource
                    ? 'resource'
                    : 'stage',
                !groupByResource && _.isEmpty(updateSelection)
                    ? null
                    : !groupByResource
                        ? {
                            ...unit.resource,
                            resource_id: updateSelection.value,
                            label: updateSelection.label,
                        }
                        : updateSelection,
            ),
        );

        setUpdateSubmitted(true);
        const kits = getKits(units);

        const eligibleUnits = [
            ...getAssemblyParents(units),
            ...getStandaloneUnits(units),
        ];

        const updatePayload = {
            ...(!groupByResource && _.isEmpty(updateSelection)
                ? {resource: null}
                : !groupByResource
                    ? {resource: {resource_id: updateSelection.value}}
                    : {stage: updateSelection}),
        };

        const numUnitsToUpdate = _.chain(units)
            .filter((unit) => !!unit.kit?.kit_id)
            .size()
            .add(_.size(eligibleUnits))
            .value();

        setUpdateAllMessage(`Updating ${numUnitsToUpdate} units`);

        const kitChunks = _.chunk(kits, 50);
        const unitChunks = _.chunk(eligibleUnits, 50);
        let numUnitsUpdated = 0;

        for (const kitChunk of kitChunks) {
            const kitIds = _.map(kitChunk, (kit) => kit.kit_id);

            const chunkUnits = _.filter(
                units,
                (unit) => _.includes(kitIds, unit.kit?.kit_id),
            );

            const response = await api.patch('/units-kits', {
                kit_id: kitIds,
                ...updatePayload,
            });

            if (isResponseError(response)) {
                toast.error(response?.data?.error);
                continue;
            }

            numUnitsUpdated += _.size(chunkUnits);

            setUpdateAllMessage(
                `Updated ${numUnitsUpdated} of ${numUnitsToUpdate} units`,
            );

            applyUpdate(chunkUnits);
        }

        for (const unitChunk of unitChunks) {
            const unitIds = _.map(unitChunk, (unit) => unit.unit_id);

            const chunkUnits = _.filter(
                units,
                (unit) => _.includes(unitIds, unit.unit_id),
            );

            const response = await api.patch('/units-kits', {
                unit_id: unitIds,
                ...updatePayload,
            });

            if (isResponseError(response)) {
                toast.error(response?.data?.error);
                continue;
            }

            numUnitsUpdated += _.size(chunkUnits);

            setUpdateAllMessage(
                `Updated ${numUnitsUpdated} of ${numUnitsToUpdate} units`,
            );

            applyUpdate(chunkUnits);
        }

        setUpdateSubmitted(false);

        if (numUnitsUpdated < numUnitsToUpdate) {
            setUpdateAllMessage(
                `Failed to update ${numUnitsToUpdate - numUnitsUpdated} units`,
            );
            return;
        }

        setUpdateSelection('');
        setShowOpenUpdateAll(false);
    }, [
        api,
        groupByResource,
        setShowOpenUpdateAll,
        setUpdateSelection,
        setUpdateSubmitted,
        units,
        updateSelection,
        updateUnitValue,
    ]);

    useEffect(() => {
        if (!_.isEmpty(units)) {
            setDisabled(checkForSerializedNullUnits(units));
            setUnitResources(
                _.chain(units)
                    .map('resource')
                    .uniqBy('resource_id')
                    .sortBy('label')
                    .value(),
            );
            setUnitStages(
                _.chain(units)
                    .map('stage')
                    .uniq()
                    .sort()
                    .value(),
            );
        }
    }, [units, groupByResource]);

    return <>
        <PageHeader
            title={'Unit Overview'}
            actionButtons={<>
                <PageActionFavorite/>
            </>}
        >
            <Row>
                <Col xs={4}>
                    <Breadcrumb>
                        <Breadcrumb.Item
                            linkAs={Link}
                            linkProps={{to: '/'}}
                        >
                            {userFacilityLabel}
                        </Breadcrumb.Item>
                        <Breadcrumb.Item
                            linkAs={Link}
                            linkProps={{to: {
                                pathname: '/work-tickets',
                                search: defaultSearchParams.workTicket,
                            }}}
                        >
                            {'Work'}
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            {workTicket?.work_ticket_number ?? '…'}
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
                <Col xs={8} className={'text-end'}>
                    <div
                        className={'text-primary'}
                        role={'button'}
                        onClick={() => {
                            setGroupByResource(!groupByResource);
                            setUpdateSelection('');
                        }}
                    >
                        {`Group by ${groupByResource ? 'Stage' : 'Resource'}`}
                    </div>
                    <div
                        className={'text-primary'}
                        role={'button'}
                        onClick={() => setShowAssetDetails(!showAssetDetails)}
                    >
                        {`${showAssetDetails ? 'Hide' : 'Show'} Asset Details`}
                    </div>
                </Col>
            </Row>
            <Row className={'pt-n4 pe-3'}>
                <Col xs={5} md={7}/>
                <Col xs={4} md={3}
                    className={'d-flex justify-content-start'}
                >
                    <Form.Text>
                        {!groupByResource ? 'Resource' : 'Stage'}
                    </Form.Text>
                </Col>
                <Col xs={3} md={2}/>
            </Row>
            <Row className={'d-flex justify-content-end'}>
                <Col xs={1} md={4}/>
                <Col xs={8} md={6}
                    className={'d-flex justify-content-end'}
                >
                    {!groupByResource
                        ? <AsyncFormSelect
                            className={'w-50'}
                            isDisabled={_.isEmpty(units)}
                            defaultOptions={defaultResources}
                            isClearable={true}
                            backspaceRemovesValue={true}
                            loadOptions={searchResources}
                            onChange={(e) => {
                                setUpdateSelection(e);
                            }}
                            value={_.find(resources,
                                {resource_id: updateSelection?.value})
                                ?? null}
                        /> : <FormSelect
                            disabled={_.isEmpty(units)}
                            className={'w-50'}
                            options={_.map(stages, (stage) => ({
                                label: stage,
                                value: stage,
                            }))}
                            value={updateSelection
                                ? {
                                    label: updateSelection,
                                    value: updateSelection,
                                }
                                : null}
                            onChange={(e) => setUpdateSelection(e?.value)}
                        />}
                </Col>
                <Col xs={3} md={2}
                    className={'d-flex justify-content-end'}
                >
                    <Button
                        className={'w-100'}
                        disabled={disabled
                            || _.isEmpty(units)
                            || _.size(units) < totalUnits}
                        variant={'primary'}
                        type={'submit'}
                        onClick={() => {
                            if (groupByResource && _.isEmpty(updateSelection)) {
                                return;
                            }
                            setShowOpenUpdateAll(true);
                        }}
                    >{'Update All'}</Button>
                    {disabled && <OverlayTrigger
                        overlay={<Popover>
                            <Popover.Body>
                                {'At least one unit is flagged as '
                                    + 'Serialized with no Serial Number, '
                                    + 'Please add the Serial Number to the '
                                    + 'unit(s) before updating all units.'}
                            </Popover.Body>
                        </Popover>}
                    >
                        <InfoIcon/>
                    </OverlayTrigger>}
                </Col>
            </Row>
        </PageHeader>
        <Container fluid={true} className={'my-2'}>
            {totalUnits === null && <>
                {'Loading units...'}
                <Spinner size={'sm'} className={'mx-2'}/>
            </>}
            {_.size(units) < totalUnits && <>
                {`Loaded ${_.size(units)} of ${totalUnits} units`}
                <Spinner size={'sm'} className={'mx-2'}/>
            </>}
        </Container>
        <Container fluid={true} className={'my-2'}>
            {groupByResource
                ? _.map(unitResources, (resource, idx) => <Accordion
                    key={idx}
                    className={'mb-2'}
                    defaultActiveKey={`${idx}`}
                >
                    <Accordion.Item eventKey={`${idx}`}>
                        <Accordion.Header>
                            {`Resource: ${resource?.label ?? 'None'}`}
                        </Accordion.Header>
                        <Accordion.Body>
                            <UnitGroup
                                units={_.filter(units, (unit) =>
                                    (unit?.resource?.resource_id ?? null)
                                        === (resource?.resource_id ?? null),
                                )}
                                workTicketId={workTicketId}
                                updateUnit={updateUnit}
                                isStageEditable={true}
                                isResourceEditable={false}
                                showAssetDetails={showAssetDetails}
                                stages={stages}
                                defaultResources={defaultResources}
                                searchResources={searchResources}
                            />
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>)
                : _.map(unitStages, (stage, idx) => <Accordion
                    key={idx}
                    className={'mb-2'}
                    defaultActiveKey={`${idx}`}
                >
                    <Accordion.Item eventKey={`${idx}`}>
                        <Accordion.Header>
                            {`Stage: ${stage}`}
                        </Accordion.Header>
                        <Accordion.Body>
                            <UnitGroup
                                units={_.filter(units, {stage})}
                                workTicketId={workTicketId}
                                updateUnit={updateUnit}
                                isStageEditable={false}
                                isResourceEditable={true}
                                showAssetDetails={showAssetDetails}
                                stages={stages}
                                defaultResources={defaultResources}
                                searchResources={searchResources}
                            />
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>)}
        </Container>
        {showOpenUpdateAll
            && <Modal
                show={showOpenUpdateAll}
                backdrop={'static'}
                keyboard={!updateSubmitted}
                onHide={() => setShowOpenUpdateAll(false)}
            >
                <Modal.Header closeButton={!updateSubmitted}>
                    <Modal.Title>Update All Units</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        {!_.isEmpty(updateSelection)
                            ? <p>
                                {'Are you sure you want to update all'}
                                {' Units to '}
                                {!groupByResource
                                    ? <em>{updateSelection.label}</em>
                                    : <em>{updateSelection}</em>
                                }
                            </p>
                            : <p>
                                {'Are you sure you want to remove'
                                    + ' Resource from All Units?'}
                            </p>
                        }
                    </Row>
                    <Row className={'align-items-center'}>
                        <Col className={'text-end'}>
                            <em>{updateAllMessage}</em>
                        </Col>
                        <Col xs={1}>
                            {updateSubmitted && <Spinner size={'sm'}/>}
                        </Col>
                        <Col xs={3}>
                            <Button
                                disabled={updateSubmitted}
                                className={'w-100'}
                                variant={'success'}
                                type={'submit'}
                                onClick={() => {
                                    updateAllUnits();
                                }}
                            >{'Update All'}</Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>}
    </>;
};

export default WorkTicketUnitOverview;
