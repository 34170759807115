import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {Link, useSearchParams} from 'react-router-dom';
import {useLocalStorage} from 'usehooks-ts';
import _ from 'lodash';
import {defaultSearchParamsObj} from '^config/defaultSearchParams';
import {limit} from '^config/pagination';
import {ApiContext} from '^contexts/api';
import {AppContext} from '^contexts/app';
import {isResponseError} from '^utilities/isResponseError';
import {usePagination} from '^utilities/usePagination';
import addIcon from '^assets/images/add.svg';
import editIcon from '^assets/images/editWhite.svg';
import {
    PageActionFavorite,
    PageActionFilter,
    PageActionIcon,
} from '^common/pageAction';
import {PageHeader} from '^common/pageHeader';
import {TableCol, TableColActions} from '^common/tableCol';
import {TableContainer} from '^common/tableContainer';
import {TableIcon} from '^common/tableIcon';
import {TableRow} from '^common/tableRow';
import StorageLocationCreateEdit
    from '^pages/storageLocation/storageLocationCreateEdit';
import {Breadcrumb, Spinner} from 'react-bootstrap';
import ActiveFilter from '^common/activeFilterAside';

const StorageLocationList = () => {
    const api = useContext(ApiContext);
    const {setAsideChildren} = useContext(AppContext);

    const [storageLocations, setStorageLocations] = useState([]);
    const [loading, setLoading] = useState(true);
    const [userFacility] = useLocalStorage('facility_id', '');
    const [userFacilityLabel] = useLocalStorage('facility_label', '');

    const [searchParams] = useSearchParams();
    const active = searchParams.get('active');

    const paramObject = useMemo(
        () => Object.fromEntries(searchParams),
        [searchParams],
    );

    const [offset, setOffset] = useState(null);

    const getStorageLocations = useCallback(async (offset) => {
        setLoading(true);

        const response = await api.get('/storage-locations', {
            params: {
                active,
                offset,
                limit,
                facility_id: userFacility,
            },
        });

        setLoading(false);

        if (isResponseError(response)) {
            return;
        }

        const results = response?.data?.results;

        setStorageLocations((prevState) => _.uniqBy(
            [...prevState, ...results],
            'storage_location_id',
        ));
        setOffset(response?.data?.next_offset);
    }, [api, active, userFacility]);

    const lastRowRef = usePagination(offset, getStorageLocations);

    useEffect(() => {
        if (api && userFacility) {
            setStorageLocations([]);
            setOffset(null);
            getStorageLocations(0);
        }
    }, [api, getStorageLocations, searchParams, userFacility]);

    const updateStorageLocation = useCallback((storageLocation) => {
        setStorageLocations((prevState) => {
            const storageLocationId = storageLocation?.storage_location_id;

            const existing = _.find(
                prevState,
                {storage_location_id: storageLocationId},
            );

            const newStorageLocations = existing
                ? _.map(prevState, (prev) =>
                    storageLocationId === prev?.storage_location_id
                        ? storageLocation
                        : prev,
                )
                : [storageLocation, ...prevState];

            return _.filter(newStorageLocations, (storageLocation) => {
                switch (active) {
                    case '0':
                        return storageLocation?.active === false;
                    case '1':
                        return storageLocation?.active === true;
                    default:
                        return true;
                }
            });
        });
    }, [active]);

    const openFilter = useCallback(() => {
        setAsideChildren(
            <ActiveFilter
                entityName={'Storage Locations'}
            />,
        );
    }, [setAsideChildren]);

    const openEditStorageLocation = useCallback((storageLocation) => {
        setAsideChildren(<StorageLocationCreateEdit
            storageLocation={storageLocation}
            updateStorageLocation={updateStorageLocation}
        />);
    }, [setAsideChildren, updateStorageLocation]);

    return <>
        <PageHeader
            title={'Storage Locations'}
            actionButtons={<>
                <PageActionFavorite/>
                <PageActionFilter
                    filterParams={paramObject}
                    onClick={openFilter}
                    defaultFilterParams={defaultSearchParamsObj.storageLocation}
                />
                <PageActionIcon
                    src={addIcon}
                    alt={'Create'}
                    disabled={!userFacility}
                    onClick={() => openEditStorageLocation(null)}
                />
            </>}
        >
            <Breadcrumb>
                <Breadcrumb.Item
                    linkAs={Link}
                    linkProps={{to: '/'}}
                >
                    {userFacilityLabel}
                </Breadcrumb.Item>
            </Breadcrumb>
        </PageHeader>
        <TableContainer>
            <TableRow $header={true}>
                <TableCol xs={10}>
                    {'Label'}
                </TableCol>
            </TableRow>
            {loading && !offset && <Spinner/>}
            {storageLocations?.length === 0 && !loading
                ? <TableRow>
                    <TableCol>
                        {'No Storage Locations found'}
                    </TableCol>
                </TableRow>
                : _.map(storageLocations, (storageLocation) =>
                    <TableRow key={storageLocation.storage_location_id}>
                        <TableCol xs={10}>
                            {`${storageLocation.label}`}
                        </TableCol>
                        <TableColActions xs={2}>
                            <TableIcon
                                src={editIcon}
                                alt={'Edit'}
                                onClick={() =>
                                    openEditStorageLocation(storageLocation)}
                                $background={'primary'}
                            />
                        </TableColActions>
                    </TableRow>)}
            {!_.isNil(offset) && <TableRow>
                <Spinner ref={lastRowRef}/>
            </TableRow>}
        </TableContainer>
    </>;
};

export default StorageLocationList;
