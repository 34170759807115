import React, {
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
import styled from 'styled-components';
import {toast} from 'react-toastify';
import {ApiContext} from '^contexts/api';
import {useParams} from 'react-router-dom';
import {isResponseError} from '^utilities/isResponseError';
import {PageActionFavorite} from '^common/pageAction';
import {PageHeader} from '^common/pageHeader';
import {Button, Col, Row, Spinner} from 'react-bootstrap';
import * as monaco from 'monaco-editor/esm/vs/editor/editor.api';

const EditorContainer = styled.div`
    min-height: calc(100svh - 16rem);;

    * {
        font-family: revert;
    }
`;

const ExtensionEditCode = () => {
    const api = useContext(ApiContext);
    const {extension_id: extensionId} = useParams();

    const editorRef = useRef(null);
    const [editor, setEditor] = useState(null);
    const [extensionLabel, setExtensionLabel] = useState('');
    const [extensionCode, setExtensionCode] = useState('');
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        if (!editorRef.current) {
            return;
        }

        setEditor((editor) => {
            if (editor) {
                return editor;
            }

            const newEditor = monaco.editor.create(editorRef.current, {
                value: extensionCode,
                language: 'html',
                hover: false,
                fontSize: 14,
                fontFamily: 'monospace',
            });

            newEditor.onDidChangeModelContent(() => {
                setExtensionCode(newEditor.getValue());
            });

            return newEditor;
        });
    }, [extensionCode, editor, setExtensionCode]);

    useEffect(() => {
        if (!editor) {
            return;
        }

        if (extensionCode !== editor.getValue()) {
            editor.setValue(extensionCode);
        }
    }, [extensionCode, editor]);

    const currentEditorRef = editorRef?.current;

    const resetEditorSize = useCallback(() => {
        if (!editor) {
            return;
        }

        if (!currentEditorRef) {
            return;
        }

        const {
            width,
            height,
        } = currentEditorRef.getBoundingClientRect();

        window.requestAnimationFrame(() => {
            editor.layout({width, height});
        });
    }, [currentEditorRef, editor]);

    useEffect(() => {
        if (!currentEditorRef) {
            return;
        }

        const observer = new ResizeObserver(resetEditorSize);
        observer.observe(currentEditorRef);

        return () => {
            observer.unobserve(currentEditorRef);
        };
    }, [currentEditorRef, resetEditorSize]);

    useEffect(() => {
        return () => {
            editor?.dispose();
        };
    }, [editor]);

    useEffect(() => {
        const fetchExtension = async () => {
            setLoading(true);
            const response = await api.get(`/extensions/${extensionId}`);
            setLoading(false);

            if (isResponseError(response)) {
                toast.error(response?.data?.error);
                return;
            }

            setExtensionLabel(response?.data?.label ?? '');
            setExtensionCode(response?.data?.code ?? '');
        };

        if (!api) {
            return;
        }

        if (!extensionId) {
            return;
        }

        fetchExtension();
    }, [api, extensionId]);

    const updateExtensionCode = useCallback(async (code) => {
        setSaving(true);

        const response = await api.patch(
            `/extensions/${extensionId}`,
            {code: extensionCode},
        );

        setSaving(false);

        if (isResponseError(response)) {
            toast.error(response?.data?.error);
        }
    }, [api, extensionCode, extensionId]);

    return <>
        <PageHeader
            title={`Edit Extension Code${extensionLabel
                ? ` (${extensionLabel})`
                : ''}`}
            actionButtons={<>
                <PageActionFavorite/>
            </>}
        />
        {loading && <Spinner/>}
        <EditorContainer ref={editorRef}/>
        <Row className={'align-items-center'}>
            <Col>
                <Button
                    className={'w-100 my-3'}
                    variant={'success'}
                    disabled={loading || saving}
                    type={'submit'}
                    onClick={updateExtensionCode}
                >
                    {'Save'}
                </Button>
            </Col>
            {saving && <Col className={'text-center'} xs={2}>
                <Spinner/>
            </Col>}
        </Row>
    </>;
};

export default ExtensionEditCode;
