import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {useLocation} from 'react-router-dom';
import styled from 'styled-components';
import _ from 'lodash';
import filterIcon from '^assets/images/filter.svg';
import starClosedIcon from '^assets/images/starClosed.svg';
import starOpenIcon from '^assets/images/starOpen.svg';
import {ApiContext} from '^contexts/api';
import {AppContext} from '^contexts/app';
import {isResponseError} from '^utilities/isResponseError';
import FavoriteCreateEdit from '^pages/favorite/favoriteCreateEdit';

const PageActionIconWrapper = styled.div.attrs(({
    disabled,
}) => ({
    className: 'd-inline position-relative ms-2',
    role: disabled
        ? 'img'
        : 'button',
}))`
    cursor: ${({onClick}) => (onClick ? 'pointer' : 'not-allowed')};
    filter: ${({disabled}) => disabled && 'grayscale(1) brightness(2)'};
`;

const PageActionIconImg = styled.img`
    height: 2rem;
    width: 2rem;
`;

const RedIndicator = styled.div.attrs(() => ({
    className: 'position-absolute bg-danger rounded-circle',
}))`
    top: -0.4rem;
    right: -0.2rem;
    width: 1rem;
    height: 1rem;
`;

export const PageActionIcon = ({
    onClick,
    disabled,
    redIndicator,
    ...rest
}) => {
    return <PageActionIconWrapper
        onClick={disabled
            ? null
            : onClick}
        disabled={disabled}
    >
        <PageActionIconImg {...rest}/>
        {redIndicator && <RedIndicator/>}
    </PageActionIconWrapper>;
};

export const PageActionFilter = ({
    filterParams,
    defaultFilterParams,
    ...rest
}) => {
    const changedDefaultFilters = useMemo(() => _.some(
        defaultFilterParams,
        (val, key) => {
            const filterVal = filterParams?.[key];

            const compareVal = _.includes(filterVal, ',')
                ? _.split(filterVal, ',')
                : filterVal;

            switch (true) {
                case _.isPlainObject(val):
                    return !_.isEqual(val, compareVal);
                case _.isArray(val):
                    return !_.isEqual(_.sortBy(val), _.sortBy(compareVal));
                default:
                    return val !== compareVal;
            }
        },
    ), [defaultFilterParams, filterParams]);

    const additionalFilters = useMemo(() => !!_.size(_.difference(
        _.keys(filterParams),
        _.keys(defaultFilterParams),
    )), [defaultFilterParams, filterParams]);

    return <PageActionIcon
        {...rest}
        src={filterIcon}
        redIndicator={changedDefaultFilters || additionalFilters}
        alt={'Filter'}
    />;
};

export const PageActionFavorite = () => {
    const api = useContext(ApiContext);
    const {setAsideChildren} = useContext(AppContext);
    const location = useLocation();

    const [loading, setLoading] = useState(true);
    const [favorite, setFavorite] = useState(null);

    const fullPath = useMemo(
        () => `${location.pathname}${location.search}`,
        [location.pathname, location.search],
    );

    useEffect(() => {
        const getIsFavorite = async () => {
            const response = await api.get(
                '/favorites',
                {params: {path: fullPath}},
            );

            if (isResponseError(response)) {
                return;
            }

            setLoading(false);
            setFavorite(response?.data?.results?.[0] ?? null);
        };

        if (!api) {
            return;
        }

        getIsFavorite();
    }, [api, fullPath]);

    const updateFavorite = useCallback((favorite) => {
        setFavorite(favorite?.path === fullPath
            ? favorite
            : null);
        setAsideChildren(null);
    }, [fullPath, setAsideChildren]);

    const openFavoriteCreateEdit = useCallback(() => {
        setAsideChildren(<FavoriteCreateEdit
            path={fullPath}
            favorite={favorite}
            updateFavorite={updateFavorite}
        />);
    }, [favorite, fullPath, setAsideChildren, updateFavorite]);

    return <PageActionIcon
        src={favorite ? starClosedIcon : starOpenIcon}
        alt={'Create'}
        disabled={loading}
        onClick={openFavoriteCreateEdit}
    />;
};
