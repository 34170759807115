import React, {useCallback, useContext, useEffect, useState} from 'react';
import _ from 'lodash';
import {ApiContext} from '^contexts/api';
import {AppContext} from '^contexts/app';
import {isResponseError} from '^utilities/isResponseError';
import addIcon from '^assets/images/add.svg';
import editIcon from '^assets/images/editWhite.svg';
import {PageActionFavorite, PageActionIcon} from '^common/pageAction';
import {PageHeader} from '^common/pageHeader';
import {TableCol, TableColActions} from '^common/tableCol';
import {TableContainer} from '^common/tableContainer';
import {TableIcon} from '^common/tableIcon';
import {TableRow} from '^common/tableRow';
import CustomerCreateEdit from '^pages/customer/customerCreateEdit';
import {Spinner} from 'react-bootstrap';
import {limit} from '^config/pagination';
import {usePagination} from '^utilities/usePagination';

const CustomerList = () => {
    const api = useContext(ApiContext);
    const {setAsideChildren} = useContext(AppContext);
    const [customers, setCustomers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [offset, setOffset] = useState(null);

    const getCustomers = useCallback(async (offset) => {
        setLoading(true);

        const response = await api.get('/customers', {
            params: {
                offset,
                limit,
            },
        });

        setLoading(false);

        if (isResponseError(response)) {
            return;
        }

        const results = response?.data?.results;

        setCustomers((prevState) => _.uniqBy(
            [...prevState, ...results],
            'customer_id',
        ));
        setOffset(response?.data?.next_offset);
    }, [api]);

    useEffect(() => {
        if (api) {
            setCustomers([]);
            setOffset(null);
            getCustomers(0);
        }
    }, [api, getCustomers]);

    const lastRowRef = usePagination(offset, getCustomers);

    const updateCustomer = useCallback((customer) => {
        setCustomers((prevState) => {
            const customerId = customer?.customer_id;

            const existing = _.find(
                prevState,
                {customer_id: customerId},
            );

            return existing
                ? _.map(prevState, (prev) =>
                    customerId === prev?.customer_id
                        ? customer
                        : prev,
                )
                : [customer, ...prevState];
        });
    }, []);

    const openCreateEditCustomer = useCallback((customer) => {
        setAsideChildren(<CustomerCreateEdit
            customer={customer}
            updateCustomer={updateCustomer}
        />);
    }, [setAsideChildren, updateCustomer]);

    return <>
        <PageHeader
            title={'Customers'}
            actionButtons={<>
                <PageActionFavorite/>
                <PageActionIcon
                    src={addIcon}
                    alt={'Create'}
                    onClick={() => openCreateEditCustomer(null)}
                />
            </>}
        />
        <TableContainer>
            <TableRow $header={true}>
                <TableCol xs={10}>
                    {'Label'}
                </TableCol>
            </TableRow>
            {loading && !offset && <Spinner/>}
            {customers?.length === 0 && !loading
                ? <TableRow>
                    <TableCol>
                        {'No Customers found'}
                    </TableCol>
                </TableRow>
                : _.map(customers, (customer) =>
                    <TableRow key={customer.customer_id}>
                        <TableCol xs={10}>
                            {`${customer.label}`}
                        </TableCol>
                        <TableColActions xs={2}>
                            <TableIcon
                                src={editIcon}
                                alt={'Edit'}
                                onClick={() => openCreateEditCustomer(customer)}
                                $background={'primary'}
                            />
                        </TableColActions>
                    </TableRow>)}
            {!_.isNil(offset) && <TableRow>
                <Spinner ref={lastRowRef}/>
            </TableRow>}
        </TableContainer>
    </>;
};

export default CustomerList;
