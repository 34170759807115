import React, {useCallback, useContext, useState} from 'react';
import _ from 'lodash';
import {ApiContext} from '^contexts/api';
import {Button, Col, Modal, Row, Spinner} from 'react-bootstrap';

const DeleteAllFavoritesModal = ({
    show,
    setShow,
    onDelete,
}) => {
    const api = useContext(ApiContext);
    const [loading, setLoading] = useState(false);

    const doDelete = useCallback(async () => {
        setLoading(true);
        let offset = null;

        do {
            const fetchResponse = await api.get(
                '/favorites',
                {params: {offset}},
            );

            offset = fetchResponse?.data?.next_offset ?? null;

            await Promise.allSettled(_.map(
                fetchResponse?.data?.results,
                async (favorite) => {
                    await api.delete(`/favorites/${favorite.favorite_id}`);
                    onDelete(favorite);
                },
            ));
        } while (offset);

        setLoading(false);
        setShow(false);
    },
    [api, onDelete, setShow]);

    return <Modal
        show={show}
        onHide={() => setShow(false)}
    >
        <Modal.Header closeButton={true}>
            <Modal.Title>{'Delete All Favorites'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Row className={'mb-3'}>
                <Col>
                    {`Are you sure you want to delete all favorites?`}
                    <br/>
                    {`This cannot be undone.`}
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button
                        disabled={loading}
                        className={'w-100'}
                        variant={'danger'}
                        type={'submit'}
                        onClick={() => doDelete()}
                    >
                        {'Delete'}
                    </Button>
                </Col>
                {loading && <Col xs={2}>
                    <Spinner/>
                </Col>}
            </Row>
        </Modal.Body>
    </Modal>;
};

export default DeleteAllFavoritesModal;
