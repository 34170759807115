import React,
{useCallback,
    useContext,
    useState} from 'react';
import {ApiContext} from '^contexts/api';
import {isResponseError} from '^utilities/isResponseError';
import {Button,
    Modal} from 'react-bootstrap';
import {toast} from 'react-toastify';

const DeleteFavoriteModal = ({
    deleteFavorite,
    setDeleteFavorite,
    onDelete,
}) => {
    const api = useContext(ApiContext);
    const [loading, setLoading] = useState(false);

    const favoriteId = deleteFavorite?.favorite_id;
    const label = deleteFavorite?.label;

    const doDelete = useCallback(async () => {
        setLoading(true);
        const response = await api.delete(`/favorites/${favoriteId}`);
        setLoading(false);

        if (isResponseError(response)) {
            toast.error(response?.data?.error);
            return;
        }

        onDelete(deleteFavorite);
        setDeleteFavorite(null);
    },
    [
        api,
        deleteFavorite,
        onDelete,
        setDeleteFavorite,
        favoriteId,
    ]);

    return <Modal
        show={!!deleteFavorite}
        onHide={() => setDeleteFavorite(null)}
    >
        <Modal.Header closeButton={true}>
            <Modal.Title>{'Delete Favorite'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className={'mb-3'}>
                {`Are you sure you want to delete "${label}"?`}
                <br/>
                {`This cannot be undone.`}
            </div>
            <div>
                <Button
                    disabled={loading}
                    className={'w-100'}
                    variant={'danger'}
                    type={'submit'}
                    onClick={() => doDelete()}
                >
                    {'Delete'}
                </Button>
            </div>
        </Modal.Body>
    </Modal>;
};

export default DeleteFavoriteModal;
