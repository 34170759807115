import React, {useContext, useEffect, useState} from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import {DateTime, Duration} from 'luxon';
import {captureFields} from '^config/captureFields';
import {
    nowDateTimeString,
    stringToReadableDateTime,
    stringsToReadableDuration,
} from '^utilities/dateTime';
import {isResponseError} from '^utilities/isResponseError';
import {ApiContext} from '^contexts/api';
import userIcon from '^assets/images/user.svg';
import AsideBackIcon from '^common/asideBackIcon';
import AsideHeader from '^common/asideHeader';
import {TableCol} from '^common/tableCol';
import {TableRow} from '^common/tableRow';
import stopwatchIcon from '^assets/images/stopwatch.svg';
import {Container, OverlayTrigger, Popover, Spinner} from 'react-bootstrap';

const UserIcon = styled.img.attrs(() => ({
    className: 'mx-1',
    role: 'button',
    src: userIcon,
}))`
  height: 1.5rem;
  width: 1.5rem;
`;

const StopwatchIcon = styled.img.attrs(() => ({
    className: 'mx-1',
    role: 'button',
    src: stopwatchIcon,
}))`
  height: 1.5rem;
  width: 1.5rem;
`;

const getReadableValue = (historyValue) => {
    let asDateTime;

    try {
        asDateTime = DateTime.fromISO(historyValue);
    } catch (e) {
        asDateTime = DateTime.invalid(e);
    }

    let asDuration;

    try {
        asDuration = Duration.fromObject(historyValue);
    } catch (e) {
        asDuration = Duration.invalid(e);
    }

    const isCurrencyLike = (val) => {
        if (!_.isPlainObject(val)) {
            return false;
        }

        if (!_.isEqual(
            _.keys(val),
            ['currency', 'value'],
        )) {
            return false;
        }

        return _.every(val, (prop) => !_.isNil(prop));
    };

    const isAssetCaptureLike = (val) => {
        if (!_.isPlainObject(val)) {
            return false;
        }

        return _.isEqual(_.keys(val), _.keys(captureFields));
    };

    switch (true) {
        case _.isString(historyValue):
            return historyValue;
        case _.isBoolean(historyValue):
            return historyValue ? 'Yes' : 'No';
        case asDateTime.isValid:
            return asDateTime.toLocaleString();
        case asDuration.isValid && asDuration.toMillis() > 0:
            return asDuration
                .shiftTo('days', 'hours', 'minutes')
                .toHuman({unitDisplay: 'short'});
        case isCurrencyLike(historyValue):
            return new Intl.NumberFormat(
                undefined, {
                    style: 'currency',
                    currency: historyValue?.currency,
                },
            ).format(historyValue?.value);
        case isAssetCaptureLike(historyValue):
            return _.map(historyValue, (val, key) => <div
                className={'text-wrap text-break'}
            >
                <div className={'d-inline fw-bold'}>
                    {`${captureFields[key]}: `}
                </div>
                <div className={'d-inline'}>
                    {getReadableValue(val)}
                </div>
            </div>);
        default:
            return 'N/A';
    }
};

const HistoryAside = ({
    label,
    baseEndpoint,
    field,
    onReturn,
}) => {
    const api = useContext(ApiContext);
    const [loading, setLoading] = useState(true);
    const [history, setHistory] = useState([]);

    const splitField = _.split(field, '.');
    const fieldParam = _.head(splitField);
    const fieldPath = _.join([
        'value',
        ..._.without(splitField, fieldParam),
    ], '.');

    useEffect(() => {
        const fetchHistory = async () => {
            setLoading(true);

            const response = await api.get(`${baseEndpoint}/audit`, {
                params: {field: fieldParam},
            });

            setLoading(false);

            if (isResponseError(response)) {
                return;
            }

            setHistory(_.map(response.data, (record, idx) => {
                const thisTimeString = record?.updated;
                const nextRecord = _.get(response.data, idx - 1);

                const duration = stringsToReadableDuration(
                    thisTimeString,
                    nextRecord?.updated ?? nowDateTimeString(),
                );

                const [readableDate, readableTime] = _.split(
                    stringToReadableDateTime(thisTimeString),
                    ', ',
                );

                return {
                    value: getReadableValue(_.get(record, fieldPath)),
                    date: readableDate,
                    time: readableTime,
                    user: record.user?.name ?? 'Unknown',
                    duration: nextRecord
                        ? duration
                        : `${duration} (and counting)`,
                };
            }));
        };

        if (api && baseEndpoint && fieldParam) {
            fetchHistory();
        }
    }, [api, baseEndpoint, fieldParam, fieldPath]);

    return <>
        <AsideHeader className={'d-flex justify-content-between'}>
            <div className={'d-flex align-items-center'}>
                <div>{onReturn && <AsideBackIcon
                    alt={'Back'}
                    onClick={onReturn}
                />}</div>
                <div>
                    <b>{`${_.startCase(fieldParam)} History`}</b>
                    <h6>{label}</h6>
                </div>
            </div>
        </AsideHeader>
        {loading
            ? <Spinner/>
            : <Container className={'px-0 pt-0 pb-3'}>
                <TableRow $header={true}>
                    <TableCol>{'Value'}</TableCol>
                    <TableCol>{'Timestamp'}</TableCol>
                    <TableCol xs={3} className={'p-0'}/>
                </TableRow>
                {_.map(history, (record, idx) => <TableRow key={idx}>
                    <TableCol>{record.value}</TableCol>
                    <TableCol>
                        <div>{record.date}</div>
                        <div>{record.time}</div>
                    </TableCol>
                    <TableCol xs={3} className={'p-0 text-center'}>
                        <OverlayTrigger
                            rootClose={true}
                            overlay={<Popover className={'mw-100'}>
                                <Popover.Body>
                                    {record.duration}
                                </Popover.Body>
                            </Popover>}
                        >
                            <StopwatchIcon/>
                        </OverlayTrigger>
                        <OverlayTrigger
                            rootClose={true}
                            overlay={<Popover className={'mw-100'}>
                                <Popover.Body>
                                    {record.user}
                                </Popover.Body>
                            </Popover>}
                        >
                            <UserIcon/>
                        </OverlayTrigger>
                    </TableCol>
                </TableRow>)}
            </Container>}
    </>;
};

export default HistoryAside;
