import React, {useCallback, useContext, useEffect, useState} from 'react';
import _ from 'lodash';
import {Duration} from 'luxon';
import {limit} from '^config/pagination';
import {ApiContext} from '^contexts/api';
import {AppContext} from '^contexts/app';
import {isResponseError} from '^utilities/isResponseError';
import {usePagination} from '^utilities/usePagination';
import editIcon from '^assets/images/editWhite.svg';
import {PageActionFavorite} from '^common/pageAction';
import {PageHeader} from '^common/pageHeader';
import {TableCol, TableColActions} from '^common/tableCol';
import {TableContainer} from '^common/tableContainer';
import {TableIcon} from '^common/tableIcon';
import {TableRow} from '^common/tableRow';
import {Spinner} from 'react-bootstrap';
import SettingEdit from './settingEdit';

const SettingList = () => {
    const api = useContext(ApiContext);
    const {setAsideChildren} = useContext(AppContext);

    const [settings, setSettings] = useState([]);
    const [loading, setLoading] = useState(true);
    const [offset, setOffset] = useState(null);

    const getSettings = useCallback(async (offset) => {
        setLoading(true);

        const response = await api.get('/settings', {
            params: {
                offset,
                limit,
            },
        });

        setLoading(false);

        if (isResponseError(response)) {
            return;
        }

        const results = response?.data?.results;

        setSettings((prevState) => _.uniqBy(
            [...prevState, ...results],
            'setting_id',
        ));
        setOffset(response?.data?.next_offset);
    }, [api]);

    useEffect(() => {
        if (api) {
            setSettings([]);
            setOffset(null);
            getSettings(0);
        }
    }, [api, getSettings]);

    const lastRowRef = usePagination(offset, getSettings);

    const updateSetting = useCallback((setting) => {
        setSettings((prevState) => _.map(
            prevState,
            (prev) => setting?.setting_id === prev.setting_id
                ? setting
                : prev,
        ));
    }, []);

    const getDisplayValue = useCallback((setting) => {
        switch (true) {
            case !_.isNil(setting?.boolean_value):
                return setting.boolean_value ? 'Yes' : 'No';
            case !_.isNil(setting?.integer_value):
                return `${setting.integer_value}`;
            case !_.isNil(setting?.string_value):
                return setting.string_value;
            case !_.isNil(setting?.duration_value):
                return Duration
                    .fromObject(setting.duration_value)
                    .shiftTo('days', 'hours', 'minutes')
                    .toHuman({unitDisplay: 'short'});
            case !_.isNil(setting?.percent_value):
                return `${setting.percent_value}%`;
            default:
                return 'N/A';
        }
    }, []);

    return <>
        <PageHeader
            title={'Settings'}
            actionButtons={<>
                <PageActionFavorite/>
            </>}
        />
        <TableContainer>
            <TableRow $header={true}>
                <TableCol xs={7} md={6}>
                    {'Setting'}
                </TableCol>
                <TableCol xs={3} md={5}>
                    {'Value'}
                </TableCol>
            </TableRow>
            {loading && !offset && <Spinner/>}
            {_.map(settings, (setting) =>
                <TableRow key={setting.setting_id}>
                    <TableCol xs={7} md={6}>
                        {`${setting.description}`}
                    </TableCol>
                    <TableCol xs={3} md={5}>
                        {getDisplayValue(setting)}
                    </TableCol>
                    <TableColActions xs={2} md={1}>
                        <TableIcon
                            src={editIcon}
                            $background={'primary'}
                            alt={'Edit'}
                            onClick={() => setAsideChildren(
                                <SettingEdit
                                    setting={setting}
                                    updateSetting={updateSetting}
                                />,
                            )}
                        />
                    </TableColActions>
                </TableRow>)}
            {!_.isNil(offset) && <TableRow>
                <Spinner ref={lastRowRef}/>
            </TableRow>}
        </TableContainer>
    </>;
};

export default SettingList;
