import React, {useCallback, useContext, useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import {ApiContext} from '^contexts/api';
import verticalEllipsis from '^assets/images/verticalEllipsis.svg';
import {isResponseError} from '^utilities/isResponseError';
import AsideHeader from '^common/asideHeader';
import {Button, Dropdown, Form} from 'react-bootstrap';
import DeleteFavoriteModal from '^pages/favorite/deleteFavoriteModal';

const FavoriteCreateEdit = ({
    path: initialPath,
    favorite,
    updateFavorite,
}) => {
    const api = useContext(ApiContext);
    const [processing, setProcessing] = useState(false);
    const [label, setLabel] = useState('');
    const [path, setPath] = useState('');
    const [deleteFavorite, setDeleteFavorite] = useState(null);

    const favoriteId = favorite?.favorite_id;

    useEffect(() => {
        setLabel(favorite?.label ?? '');
        setPath(favorite?.path ?? initialPath ?? '');
    }, [favorite?.label, favorite?.path, initialPath]);

    const createEditFavorite = useCallback(async () => {
        setProcessing(true);
        const body = {label, path};

        const response = favoriteId
            ? await api.patch(`/favorites/${favoriteId}`, body)
            : await api.post('/favorites', body);

        setProcessing(false);

        if (isResponseError(response)) {
            toast.error(response?.data?.error);
            return;
        }

        updateFavorite(response?.data);
    }, [
        api,
        favoriteId,
        label,
        path,
        updateFavorite,
    ]);

    return <>
        <AsideHeader className={'d-flex justify-content-between'}>
            <b>{favoriteId ? 'Edit Favorite' : 'Create New Favorite'}</b>
            {favoriteId && <Dropdown className={'mt-n1 pe-4'}>
                <Dropdown.Toggle
                    as={'img'}
                    role={'button'}
                    src={verticalEllipsis}
                    alt={'Dropdown Toggle'}
                    height={'20'}
                    width={'20'}
                />
                <Dropdown.Menu>
                    <Dropdown.Item onClick={() => setDeleteFavorite(favorite)}>
                        {'Delete'}
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>}
        </AsideHeader>
        <Form onSubmit={(event) => {
            event.preventDefault();
            createEditFavorite();
        }}>
            <Form.Group className={'mb-3'}>
                <Form.Text>{'Label'}</Form.Text>
                <Form.Control
                    disabled={processing}
                    type={'text'}
                    required={true}
                    value={label}
                    onChange={(e) => setLabel(e.target.value)}
                />
            </Form.Group>
            <Form.Group className={'mb-3'}>
                <Form.Text>{'Path'}</Form.Text>
                <Form.Control
                    disabled={processing}
                    type={'text'}
                    required={true}
                    value={path}
                    onChange={(e) => setPath(e.target.value)}
                />
            </Form.Group>
            <Button
                className={'w-100 my-3'}
                variant={'success'}
                disabled={processing || !label || !path}
                type={'submit'}
            >
                {'Submit'}
            </Button>
        </Form>
        <DeleteFavoriteModal
            deleteFavorite={deleteFavorite}
            setDeleteFavorite={setDeleteFavorite}
            onDelete={() => updateFavorite(null)}
        />
    </>;
};

export default FavoriteCreateEdit;
