import React, {useCallback, useContext, useEffect, useState} from 'react';
import _ from 'lodash';
import {ApiContext} from '^contexts/api';
import {AppContext} from '^contexts/app';
import {isResponseError} from '^utilities/isResponseError';
import UserEdit from '^pages/user/userEdit';
import editIcon from '^assets/images/editWhite.svg';
import {PageActionFavorite} from '^common/pageAction';
import {PageHeader} from '^common/pageHeader';
import {TableCol, TableColActions} from '^common/tableCol';
import {TableContainer} from '^common/tableContainer';
import {TableIcon} from '^common/tableIcon';
import {TableRow} from '^common/tableRow';
import {Spinner} from 'react-bootstrap';
import {limit} from '^config/pagination';
import {usePagination} from '^utilities/usePagination';

const UserList = () => {
    const api = useContext(ApiContext);
    const {setAsideChildren} = useContext(AppContext);
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [offset, setOffset] = useState(null);

    const getUsers = useCallback(async (offset) => {
        setLoading(true);

        const response = await api.get('/users', {
            params: {
                offset,
                limit,
            },
        });

        setLoading(false);

        if (isResponseError(response)) {
            return;
        }

        const results = response?.data?.results;

        setUsers((prevState) => _.uniqBy(
            [...prevState, ...results],
            'user_id',
        ));
        setOffset(response?.data?.next_offset);
    }, [api]);

    useEffect(() => {
        if (api) {
            setUsers([]);
            setOffset(null);
            getUsers(0);
        }
    }, [api, getUsers]);

    const lastRowRef = usePagination(offset, getUsers);

    const updateUser = useCallback((user) => {
        setUsers((prevState) => _.map(prevState, (prev) =>
            user?.user_id === prev?.user_id
                ? user
                : prev,
        ));
    }, []);

    const openEditUser = useCallback((user) => {
        setAsideChildren(<UserEdit
            user={user}
            updateUser={updateUser}
        />);
    }, [setAsideChildren, updateUser]);

    return <>
        <PageHeader
            title={'Users'}
            actionButtons={<>
                <PageActionFavorite/>
            </>}
        />
        <TableContainer>
            <TableRow $header={true}>
                <TableCol xs={4} xl={2}>
                    {'Name'}
                </TableCol>
                <TableCol xs={4} xl={2}>
                    {'Email'}
                </TableCol>
            </TableRow>
            {loading && !offset && <Spinner/>}
            {users?.length === 0 && !loading
                ? <TableRow>
                    <TableCol>
                        {'No Users found'}
                    </TableCol>
                </TableRow>
                : _.map(users, (user) =>
                    <TableRow key={user.user_id}>
                        <TableCol xs={4} xl={2}>
                            {`${user.name}`}
                        </TableCol>
                        <TableCol xs={4} xl={2}>
                            {`${user.email}`}
                        </TableCol>
                        <TableColActions xs={4} xl={8}>
                            <TableIcon
                                src={editIcon}
                                alt={'Edit'}
                                $background={'primary'}
                                onClick={() => openEditUser(user)}
                            />
                        </TableColActions>
                    </TableRow>,
                )}
            {!_.isNil(offset) && <TableRow>
                <Spinner ref={lastRowRef}/>
            </TableRow>}
        </TableContainer>
    </>;
};

export default UserList;
