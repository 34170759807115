import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {useParams} from 'react-router-dom';
import styled from 'styled-components';
import axios from 'axios';
import _ from 'lodash';
import {DateTime} from 'luxon';
import papa from 'papaparse';
import {CSVLink} from 'react-csv';
import {ApiContext} from '^contexts/api';
import {AppContext} from '^contexts/app';
import exportIcon from '^assets/images/export.svg';
import filterIcon from '^assets/images/filter.svg';
import {isResponseError} from '^utilities/isResponseError';
import {PageHeader} from '^common/pageHeader';
import {PageActionFavorite, PageActionIcon} from '^common/pageAction';
import {TableContainer} from '^common/tableContainer';
import {toast} from 'react-toastify';
import {Spinner, Table} from 'react-bootstrap';
import reportConfig from './reportConfig';

const TransparentTable = styled(Table)`
    --bs-table-bg: transparent;
`;

const Th = styled.th.attrs(() => ({
    className: 'text-primary',
}))`
    min-width: ${({width}) => width ?? '15ch'};
`;

const mapTypes = (types, data) => {
    const headers = data?.[0];
    const idxMap = _.mapKeys(types, (val, key) => _.indexOf(headers, key));

    return [
        ...headers ? [headers] : [],
        ..._.map(data?.slice?.(1), (row) => _.map(row, (col, colIdx) => {
            if (col === '') {
                return 'N/A';
            }

            switch (idxMap[colIdx]) {
                case 'boolean':
                    return col === 'true'
                        ? 'Yes'
                        : 'No';
                case 'date':
                    return DateTime
                        .fromISO(col, {zone: 'UTC'})
                        .toLocaleString();
                case 'datetime':
                    return DateTime
                        .fromISO(col)
                        .toLocaleString({
                            ...DateTime.DATETIME_SHORT,
                            timeZoneName: 'short',
                        });
                default:
                    return col;
            }
        })),
    ];
};

const ReportRun = () => {
    const api = useContext(ApiContext);
    const {setAsideChildren} = useContext(AppContext);
    const {type: reportType} = useParams();

    const [loading, setLoading] = useState(false);
    const [reportId, setReportId] = useState(null);
    const [data, setData] = useState(null);

    const reportTypeConfig = useMemo(
        () => reportConfig[reportType],
        [reportType],
    );

    const pollReportStatus = useCallback(async (reportId) => {
        const response = await api.get(`/reports/${reportId}`);

        if (isResponseError(response)) {
            toast.error(response?.data?.error);
            setLoading(false);
            return;
        }

        if (response?.data?.status === 'ERROR') {
            toast.error('Report execution failed');
            setLoading(false);
            return;
        }

        if (response?.data?.download?.url) {
            setReportId(reportId);
            const csvResponse = await axios.get(response?.data?.download?.url);
            const data = papa.parse(csvResponse?.data)?.data;
            setData(mapTypes(reportTypeConfig?.types, data));
            setLoading(false);
            return;
        }

        await new Promise((x) => setTimeout(x, 500));
        await pollReportStatus(reportId);
    }, [api, reportTypeConfig]);

    const runReport = useCallback(async (options) => {
        setLoading(true);
        setReportId(null);
        setData(null);
        setAsideChildren(null);

        const response = await api.post(
            '/reports',
            {
                type: reportType,
                options: {...options},
            },
            // Don't normalize request body for reports
            {transformRequest: axios.defaults.transformRequest},
        );

        if (isResponseError(response)) {
            toast.error(response?.data?.error);
            setLoading(false);
            return;
        }

        await pollReportStatus(response?.data?.report_id);
    }, [api, pollReportStatus, reportType, setAsideChildren]);

    const reportAside = useMemo(() => {
        const ReportAside = reportTypeConfig?.Aside;

        return ReportAside
            ? <ReportAside onSubmit={runReport}/>
            : null;
    }, [reportTypeConfig, runReport]);

    useEffect(() => {
        setAsideChildren(reportAside);
    }, [reportAside, setAsideChildren]);

    return <>
        <PageHeader
            title={`${reportTypeConfig?.label ?? 'Invalid'} Report`}
            actionButtons={<>
                <PageActionFavorite/>
                {data && !_.isEqual(data, []) && <CSVLink
                    data={data}
                    filename={`${reportId}.csv`}
                >
                    <PageActionIcon
                        src={exportIcon}
                        alt={'Export'}
                        onClick={_.noop}
                    />
                </CSVLink>}
                {reportTypeConfig && <PageActionIcon
                    src={filterIcon}
                    alt={'Filter'}
                    onClick={() => setAsideChildren(reportAside)}
                />}
            </>}
        />
        <TableContainer className={'mt-3'}>
            {loading && <Spinner size={'lg'}/>}
            {_.isEqual(data, []) && <div
                className={'fw-bold text-danger'}
            >
                {'No Results'}
            </div>}
            <TransparentTable>
                <thead className={'border-bottom border-black'}>
                    <tr>
                        {_.map(data?.[0], (header, headerIdx) => <Th
                            key={headerIdx}
                            width={reportTypeConfig?.widths?.[header]}
                        >
                            {header}
                        </Th>)}
                    </tr>
                </thead>
                <tbody
                    className={'border border-black'}
                >
                    {_.map(data?.slice?.(1), (row, rowIdx) => <tr
                        key={rowIdx}
                        className={'bg-light'}
                    >
                        {_.map(row, (col, colIdx) => <td
                            key={colIdx}
                            className={'text-primary'}
                        >
                            {col}
                        </td>)}
                    </tr>)}
                </tbody>
            </TransparentTable>
        </TableContainer>
    </>;
};

export default ReportRun;
