import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {useSearchParams} from 'react-router-dom';
import _ from 'lodash';
import {ApiContext} from '^contexts/api';
import {AppContext} from '^contexts/app';
import {defaultSearchParamsObj} from '^config/defaultSearchParams';
import {isResponseError} from '^utilities/isResponseError';
import addIcon from '^assets/images/add.svg';
import editIcon from '^assets/images/editWhite.svg';
import {
    PageActionFavorite,
    PageActionFilter,
    PageActionIcon,
} from '^common/pageAction';
import {PageHeader} from '^common/pageHeader';
import {TableCol, TableColActions} from '^common/tableCol';
import {TableContainer} from '^common/tableContainer';
import {TableIcon} from '^common/tableIcon';
import {TableRow} from '^common/tableRow';
import FacilityCreateEdit from '^pages/facility/facilityCreateEdit';
import {Spinner} from 'react-bootstrap';
import ActiveFilter from '^common/activeFilterAside';

const FacilityList = () => {
    const api = useContext(ApiContext);
    const {setAsideChildren} = useContext(AppContext);
    const [facilities, setFacilities] = useState([]);
    const [loading, setLoading] = useState(true);

    const [searchParams] = useSearchParams();
    const active = searchParams.get('active');

    const paramObject = useMemo(
        () => Object.fromEntries(searchParams),
        [searchParams],
    );

    useEffect(() => {
        const getFacilities = async () => {
            setLoading(true);

            const response = await api.get('/facilities', {
                params: {
                    active,
                },
            });

            setLoading(false);

            if (isResponseError(response)) {
                return;
            }

            setFacilities(response?.data?.results);
        };

        if (api) {
            getFacilities();
        }
    }, [api, active]);

    const updateFacility = useCallback((facility) => {
        setFacilities((prevState) => {
            const facilityId = facility?.facility_id;

            const existing = _.find(
                prevState,
                {facility_id: facilityId},
            );

            const newFacilities = existing
                ? _.map(prevState, (prev) =>
                    facilityId === prev?.facility_id
                        ? facility
                        : prev,
                )
                : [facility, ...prevState];

            return _.filter(newFacilities, (facility) => {
                switch (active) {
                    case '0':
                        return facility?.active === false;
                    case '1':
                        return facility?.active === true;
                    default:
                        return true;
                }
            });
        });
    }, [active]);

    const openFilter = useCallback(() => {
        setAsideChildren(
            <ActiveFilter
                entityName={'Facilities'}
            />,
        );
    }, [setAsideChildren]);

    const openCreateEditFacility = useCallback((facility) => {
        setAsideChildren(<FacilityCreateEdit
            facility={facility}
            updateFacility={updateFacility}
        />);
    }, [setAsideChildren, updateFacility]);

    return <>
        <PageHeader
            title={'Facilities'}
            actionButtons={<>
                <PageActionFavorite/>
                <PageActionFilter
                    filterParams={paramObject}
                    onClick={openFilter}
                    defaultFilterParams={defaultSearchParamsObj.facility}
                />
                <PageActionIcon
                    src={addIcon}
                    alt={'Create'}
                    onClick={() => openCreateEditFacility(null)}
                />
            </>}
        />
        <TableContainer>
            <TableRow $header={true}>
                <TableCol xs={3} xl={2}>
                    {'Label'}
                </TableCol>
                <TableCol xs={3} xl={2}>
                    {'Code'}
                </TableCol>
                <TableCol xs={4} xl={2}>
                    {'Time Zone'}
                </TableCol>
            </TableRow>
            {loading && <Spinner/>}
            {facilities?.length === 0 && !loading
                ? <TableRow>
                    <TableCol>
                        {'No Facilities found'}
                    </TableCol>
                </TableRow>
                : _.map(facilities, (facility) =>
                    <TableRow key={facility.facility_id}>
                        <TableCol xs={3} xl={2}>
                            {`${facility.label}`}
                        </TableCol>
                        <TableCol xs={3} xl={2}>
                            {`${facility.code}`}
                        </TableCol>
                        <TableCol xs={4} xl={2}>
                            {`${facility.time_zone}`}
                        </TableCol>
                        <TableColActions xs={2} xl={6}>
                            <TableIcon
                                src={editIcon}
                                alt={'Edit'}
                                onClick={() =>
                                    openCreateEditFacility(facility)}
                                $background={'primary'}
                            />
                        </TableColActions>
                    </TableRow>)}
        </TableContainer>
    </>;
};

export default FacilityList;
