import React, {useCallback, useContext, useEffect, useState} from 'react';
import {createSearchParams, Link, useSearchParams} from 'react-router-dom';
import {useLocalStorage} from 'usehooks-ts';
import {toast} from 'react-toastify';
import settingIcon from '^assets/images/setting.svg';
import {ApiContext} from '^contexts/api';
import {AppContext} from '^contexts/app';
import {isResponseError} from '^utilities/isResponseError';
import {useScrollButtons} from '^utilities/useScrollButtons';
import {PageHeader} from '^common/pageHeader';
import {PageActionFavorite, PageActionIcon} from '^common/pageAction';
import {
    Breadcrumb,
    Button,
    Col,
    Container,
    Form,
    Row,
    Spinner,
} from 'react-bootstrap';
import SettingsAside from './settingsAside';
import WorkTicketResult from './workTicketResult';

const AssetCapture = () => {
    const api = useContext(ApiContext);
    const {setAsideChildren} = useContext(AppContext);
    const [searchParams, setSearchParams] = useSearchParams();
    const [userFacility] = useLocalStorage('facility_id', '');
    const [userFacilityLabel] = useLocalStorage('facility_label', '');

    useScrollButtons();

    const [stages, setStages] = useState([]);
    const [statuses, setStatuses] = useState([]);
    const [searchWorkTicketNumber, setSearchWorkTicketNumber] = useState('');
    const [resultsSummary, setResultsSummary] = useState('');
    const [workTicket, setWorkTicket] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchStages = async () => {
            const response = await api.get('/types/stage');

            if (isResponseError(response)) {
                return;
            }

            setStages(response?.data);
        };

        if (api) {
            fetchStages();
        }
    }, [api]);

    useEffect(() => {
        const fetchStatuses = async () => {
            const response = await api.get('/types/status');

            if (isResponseError(response)) {
                return;
            }

            setStatuses(response?.data);
        };

        if (api) {
            fetchStatuses();
        }
    }, [api]);

    const searchForWorkTicket = useCallback(async (workTicketNumber) => {
        setLoading(true);
        setResultsSummary('');

        const response = await api.get('/work-tickets', {
            params: {
                work_ticket_number: workTicketNumber,
                facility_id: userFacility,
            },
        });

        setLoading(false);

        if (isResponseError(response)) {
            toast.error(response?.data?.error);
            return;
        }

        if (response?.data?.total_count === 0) {
            setResultsSummary('No results found for'
                + ` work "${workTicketNumber}".`);
            return;
        }

        setResultsSummary(`Found work "${workTicketNumber}".`);
        setWorkTicket(response?.data?.results?.[0]);
    }, [api, userFacility]);

    const searchSubmit = useCallback(() => {
        setSearchWorkTicketNumber('');

        if (searchWorkTicketNumber) {
            setSearchParams(createSearchParams({
                work_ticket_number: searchWorkTicketNumber,
            }));
        }
    }, [searchWorkTicketNumber, setSearchParams]);

    useEffect(() => {
        const workTicketNumber = searchParams.get('work_ticket_number');

        if (workTicketNumber) {
            setWorkTicket(null);
            searchForWorkTicket(workTicketNumber);
        }
    }, [searchForWorkTicket, searchParams]);

    return <>
        <PageHeader
            title={'Asset Capture'}
            actionButtons={<>
                <PageActionFavorite/>
                <PageActionIcon
                    src={settingIcon}
                    alt={'Settings'}
                    onClick={() => setAsideChildren(<SettingsAside/>)}
                />
            </>}
        >
            <Row>
                <Col xs={6}>
                    <Breadcrumb>
                        <Breadcrumb.Item
                            linkAs={Link}
                            linkProps={{to: '/'}}
                        >
                            {userFacilityLabel}
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            {'Asset Capture'}
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
        </PageHeader>
        <Container fluid={true} className={'my-2'}>
            <Form
                onSubmit={(e) => {
                    e.preventDefault();
                    searchSubmit();
                }}
            >
                <Row className={'justify-content-center'}>
                    <Col xs={8} md={3} xl={2}>
                        <Form.Control
                            type={'search'}
                            placeholder={`Search Work Number`}
                            value={searchWorkTicketNumber}
                            onChange={(e) => {
                                setSearchWorkTicketNumber(e?.target?.value);
                            }}
                        />
                    </Col>
                    <Col xs={4} md={1}>
                        <Button
                            className={'w-100'}
                            type={'submit'}
                            variant={'success'}
                        >
                            {'Go'}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Container>
        <Container fluid={true} className={'my-3'}>
            <Row className={'my-3 justify-content-center fw-bold'}>
                {loading && <Spinner/>}
                {resultsSummary}
            </Row>
            {workTicket && <WorkTicketResult
                workTicket={workTicket}
                stages={stages}
                statuses={statuses}
            />}
        </Container>
    </>;
};

export default AssetCapture;
