import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import _ from 'lodash';
import {limit} from '^config/pagination';
import editIcon from '^assets/images/editWhite.svg';
import deleteIcon from '^assets/images/crossWhite.svg';
import verticalEllipsis from '^assets/images/verticalEllipsis.svg';
import {ApiContext} from '^contexts/api';
import {AppContext} from '^contexts/app';
import {isResponseError} from '^utilities/isResponseError';
import {usePagination} from '^utilities/usePagination';
import AsideHeader from '^common/asideHeader';
import {TableCol, TableColActions} from '^common/tableCol';
import {TableIcon} from '^common/tableIcon';
import {Dropdown, Row, Spinner, Table} from 'react-bootstrap';
import FavoriteCreateEdit from '^pages/favorite/favoriteCreateEdit';
import DeleteFavoriteModal from '^pages/favorite/deleteFavoriteModal';
import DeleteAllFavoritesModal from '^pages/favorite/deleteAllFavoritesModal';

const FavoritesAside = () => {
    const api = useContext(ApiContext);
    const {setAsideChildren} = useContext(AppContext);

    const [favorites, setFavorites] = useState([]);
    const [loading, setLoading] = useState(true);
    const [offset, setOffset] = useState(null);
    const [editFavorite, setEditFavorite] = useState(null);
    const [deleteFavorite, setDeleteFavorite] = useState(null);
    const [showDeleteAllFavorites, setShowDeleteAllFavorites] = useState(false);

    const getFavorites = useCallback(async (offset) => {
        setLoading(true);

        const response = await api.get(
            '/favorites',
            {params: {offset, limit}},
        );

        setLoading(false);

        if (isResponseError(response)) {
            return;
        }

        const results = response?.data?.results;

        setFavorites((prevState) => _.uniqBy(
            [...prevState, ...results],
            'favorite_id',
        ));
        setOffset(response?.data?.next_offset);
    }, [api]);

    useEffect(() => {
        if (api) {
            setFavorites([]);
            setOffset(null);
            getFavorites(0);
        }
    }, [api, getFavorites]);

    const lastRowRef = usePagination(offset, getFavorites);

    const onDelete = useCallback((favorite) => {
        const favoriteId = favorite.favorite_id;

        setFavorites((prevState) => _.filter(
            prevState,
            (prev) => prev.favorite_id !== favoriteId,
        ));
    }, []);

    if (editFavorite) {
        return <FavoriteCreateEdit
            favorite={editFavorite}
            updateFavorite={(favorite) => {
                setFavorites((prevState) => {
                    if (_.isNil(favorite)) {
                        return _.filter(
                            prevState,
                            (prev) =>
                                prev.favorite_id !== editFavorite.favorite_id,
                        );
                    }

                    return _.map(prevState, (prev) =>
                        prev?.favorite_id === favorite?.favorite_id
                            ? favorite
                            : prev,
                    );
                });

                setEditFavorite(null);
            }}
        />;
    }

    return <>
        <AsideHeader className={'d-flex justify-content-between'}>
            <b>{'Favorites'}</b>
            <Dropdown className={'mt-n1 pe-4'}>
                <Dropdown.Toggle
                    as={'img'}
                    role={'button'}
                    src={verticalEllipsis}
                    alt={'Dropdown Toggle'}
                    height={'20'}
                    width={'20'}
                />
                <Dropdown.Menu>
                    <Dropdown.Item
                        onClick={() => setShowDeleteAllFavorites(true)}
                    >
                        {'Delete All'}
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </AsideHeader>
        <Table>
            {loading && !offset && <Spinner/>}
            {favorites?.length === 0 && !loading
                ? <Row>
                    <TableCol>
                        {'No Favorites found'}
                    </TableCol>
                </Row>
                : _.map(favorites, (favorite) =>
                    <Row className={'py-1'} key={favorite.favorite_id}>
                        <TableCol xs={8}>
                            <Link
                                className={_.join([
                                    'ps-0',
                                    'text-primary',
                                    'text-decoration-none',
                                ], ' ')}
                                to={favorite.path}
                                onClick={() => setAsideChildren(null)}
                            >
                                {favorite.label}
                            </Link>
                        </TableCol>
                        <TableColActions xs={4}>
                            <TableIcon
                                src={editIcon}
                                alt={'Edit'}
                                onClick={() => setEditFavorite(favorite)}
                                $background={'primary'}
                            />
                            <TableIcon
                                src={deleteIcon}
                                alt={'Delete'}
                                $background={'danger'}
                                onClick={() => setDeleteFavorite(favorite)}
                            />
                        </TableColActions>
                    </Row>)}
            {!_.isNil(offset) && <Row>
                <Spinner ref={lastRowRef}/>
            </Row>}
        </Table>
        <DeleteFavoriteModal
            deleteFavorite={deleteFavorite}
            setDeleteFavorite={setDeleteFavorite}
            onDelete={onDelete}
        />
        <DeleteAllFavoritesModal
            show={showDeleteAllFavorites}
            setShow={setShowDeleteAllFavorites}
            onDelete={onDelete}
        />
    </>;
};

export default FavoritesAside;
