import React, {useContext, useEffect, useMemo, useState} from 'react';
import {ApiContext} from '^contexts/api';
import {useParams} from 'react-router-dom';
import {apiHost, logRocketId} from '^utilities/dataSet';
import {isResponseError} from '^utilities/isResponseError';
import {PageActionFavorite} from '^common/pageAction';
import {PageHeader} from '^common/pageHeader';
import {Spinner} from 'react-bootstrap';
import {toast} from 'react-toastify';
import styled from 'styled-components';

const IframeContainer = styled.iframe`
    height: calc(100svh - 12rem);
    width: 100%;
`;

const ExtensionLaunch = () => {
    const api = useContext(ApiContext);
    const {extension_id: extensionId} = useParams();

    const [extensionLabel, setExtensionLabel] = useState('');
    const [extensionCode, setExtensionCode] = useState('');
    const [loading, setLoading] = useState(true);

    const logRocketImportScript = useMemo(() => {
        const script = document.createElement('script');
        script.src = 'https://cdn.logr-ingest.com/LogRocket.min.js';
        script.crossOrigin = 'anonymous';
        return script;
    }, []);

    const logRocketInitScript = useMemo(() => {
        const initFunction = () => {
            const logRocketId = document
                .getElementsByTagName('body')
                ?.[0]
                ?.dataset
                ?.logRocketId;

            if (logRocketId) window.LogRocket.init(logRocketId);
        };

        const script = document.createElement('script');
        script.text = `(${initFunction.toString()})()`;
        return script;
    }, []);

    const iframeBody = useMemo(() => {
        const body = document.createElement('body');
        body.dataset.apiHost = apiHost;
        body.dataset.logRocketId = logRocketId;
        body.innerHTML = extensionCode;
        body.prepend(logRocketImportScript, logRocketInitScript);
        return body;
    }, [extensionCode, logRocketImportScript, logRocketInitScript]);

    useEffect(() => {
        const fetchExtension = async () => {
            setLoading(true);
            const response = await api.get(`/extensions/${extensionId}`);
            setLoading(false);

            if (isResponseError(response)) {
                toast.error(response?.data?.error);
                return;
            }

            if (response?.data?.active === false) {
                toast.error('Extension is inactive');
                return;
            }

            setExtensionLabel(response?.data?.label ?? '');
            setExtensionCode(response?.data?.code ?? '');
        };

        if (!api) {
            return;
        }

        if (!extensionId) {
            return;
        }

        fetchExtension();
    }, [api, extensionId]);

    return <>
        <PageHeader
            title={`Extension${extensionLabel
                ? ` (${extensionLabel})`
                : ''}`}
            actionButtons={<>
                <PageActionFavorite/>
            </>}
        />
        {loading
            ? <Spinner/>
            : <IframeContainer
                title={extensionLabel}
                srcDoc={iframeBody.outerHTML}
            />}
    </>;
};

export default ExtensionLaunch;
