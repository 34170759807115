import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import _ from 'lodash';
import {limit} from '^config/pagination';
import {ApiContext} from '^contexts/api';
import {AppContext} from '^contexts/app';
import {isResponseError} from '^utilities/isResponseError';
import {usePagination} from '^utilities/usePagination';
import configIcon from '^assets/images/config.svg';
import {PageActionFavorite, PageActionFilter} from '^common/pageAction';
import {PageHeader} from '^common/pageHeader';
import {TableCol, TableColActions} from '^common/tableCol';
import {TableContainer} from '^common/tableContainer';
import {TableIcon, TableIconText} from '^common/tableIcon';
import {DropdownToggle} from '^common/dropdownToggle';
import {TableRow} from '^common/tableRow';
import {Dropdown, Form, Spinner} from 'react-bootstrap';
import PartAside from '^pages/part/partAside';
import PartEdit from '^pages/part/partEdit';
import PartFilterAside from './partFilterAside';
import MetricsWarningIcon from './common/metricsWarningIcon';

const PartList = () => {
    const api = useContext(ApiContext);
    const navigate = useNavigate();
    const {setAsideChildren} = useContext(AppContext);
    const [parts, setParts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [offset, setOffset] = useState(null);

    const [searchParams] = useSearchParams();
    const isService = searchParams.get('is_service');
    const partNumber = searchParams.get('part_number');
    const hasAllDurations = searchParams.get('has_all_durations');

    const paramObject = useMemo(
        () => Object.fromEntries(searchParams),
        [searchParams],
    );

    const getParts = useCallback(async (offset) => {
        setLoading(true);

        const response = await api.get('/parts', {
            params: {
                part_number: partNumber,
                is_service: isService,
                has_all_durations: hasAllDurations,
                offset,
                limit,
            },
        });

        setLoading(false);

        if (isResponseError(response)) {
            return;
        }

        const results = response?.data?.results;

        setParts((prevState) => _.uniqBy(
            [...prevState, ...results],
            'part_id',
        ));
        setOffset(response?.data?.next_offset);
    }, [api, hasAllDurations, isService, partNumber]);

    useEffect(() => {
        if (api) {
            setParts([]);
            setOffset(null);
            getParts(0);
        }
    }, [api, getParts]);

    const lastRowRef = usePagination(offset, getParts);

    const updatePart = useCallback((part) => {
        setParts((prevState) => _.map(
            prevState,
            (prev) => part?.part_id === prev.part_id
                ? part
                : prev,
        ));
    }, []);

    return <>
        <PageHeader
            title={'Parts'}
            actionButtons={<>
                <PageActionFavorite/>
                <PageActionFilter
                    filterParams={paramObject}
                    onClick={() => setAsideChildren(<PartFilterAside/>)}
                    defaultFilterParams={null}
                />
            </>}
        />
        <TableContainer>
            <TableRow $header={true}>
                <TableCol xs={5} lg={2}>
                    {'Part #'}
                </TableCol>
                <TableCol xs={5} lg={2}>
                    {'Manufacturer Part #'}
                </TableCol>
                <TableCol xs={2} className={'d-none d-lg-block'}>
                    {'Type'}
                </TableCol>
                <TableCol xs={3} className={'d-none d-lg-block'}>
                    {'Description'}
                </TableCol>
                <TableCol xs={1} className={'text-center d-none d-lg-block'}>
                    {'Service'}
                </TableCol>
                <TableCol xs={1} className={'text-center d-none d-lg-block'}>
                    {'Metrics'}
                </TableCol>
            </TableRow>
            {loading && !offset && <Spinner/>}
            {parts?.length === 0 && !loading
                ? <TableRow>
                    <TableCol>
                        {'No Parts found'}
                    </TableCol>
                </TableRow>
                : _.map(parts, (part) =>
                    <TableRow key={part.part_id}>
                        <TableCol xs={5} lg={2}>
                            {`${part.part_number}`}
                        </TableCol>
                        <TableCol xs={5} lg={2}>
                            {`${part.manufacturer_part_number ?? ''}`}
                        </TableCol>
                        <TableCol xs={2} className={'d-none d-lg-block'}>
                            {`${part.type}`}
                        </TableCol>
                        <TableCol xs={3} className={'d-none d-lg-block'}>
                            {`${part.description}`}
                        </TableCol>
                        <TableCol xs={1} className={'d-none d-lg-block'}>
                            <Form.Check
                                className={'text-center'}
                                checked={part.is_service}
                                readOnly={true}
                            />
                        </TableCol>
                        <TableCol xs={1} className={'d-none d-lg-block'}>
                            {part.is_service
                                && !part.has_all_durations
                                && <MetricsWarningIcon/>}
                        </TableCol>
                        <TableColActions xs={2} lg={1}>
                            <Link
                                to={`/parts/${part?.part_id}/configs`}
                            >
                                <TableIcon
                                    src={configIcon}
                                    alt={'Configs'}
                                />
                            </Link>
                            <TableIconText $characters={3}>
                                {part?.config_count}
                            </TableIconText>
                            <Dropdown>
                                <Dropdown.Toggle as={DropdownToggle}/>
                                <Dropdown.Menu>
                                    <Dropdown.Item
                                        onClick={() => setAsideChildren(
                                            <PartAside part={part}/>,
                                        )}
                                    >
                                        {'View Part Details'}
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={() => setAsideChildren(
                                            <PartEdit
                                                part={part}
                                                updatePart={updatePart}
                                            />,
                                        )}
                                    >
                                        {'Edit Part'}
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={() => navigate(
                                        `/parts/${part?.part_id}/configs`,
                                    )}>
                                        {'View Configs'}
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </TableColActions>
                    </TableRow>)}
            {!_.isNil(offset) && <TableRow>
                <Spinner ref={lastRowRef}/>
            </TableRow>}
        </TableContainer>
    </>;
};

export default PartList;
